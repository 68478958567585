body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

p {
  margin: 0;
}

a {
  color: #0288D1;
}

textarea, input {
  outline: none;
  -webkit-appearance: none;
}

.rdw-option-wrapper {
  border: 0;
}
.rdw-dropdown-selectedtext {
  color: inherit;
}

.rdw-editor-toolbar {
  background-color: #fff;
}

.rdw-editor-main {
  font-size: 1rem;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

html {
  font-size: 0.875rem !important;
}

@media only screen and (min-width: 1441px) {
  html {
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 2000px) {
  html {
    font-size: 1.125rem !important;
  }
}

.mapboxgl-ctrl-logo {
  margin-bottom: -11px !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0
}

.mapboxgl-popup-content {
  display: flex;
  flex-direction: row;
  background-color: #FFF !important;
  padding: 0.5rem 1rem !important;
  box-shadow: 0px 0px 4px 0px #00000040 !important;
  border-radius: 0.3125rem !important;
}

.rdw-image-imagewrapper img {
  max-width: 100%;
}

figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}