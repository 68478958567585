.html-input--container {
  border: 2px solid #ccc;
  border-radius: 3px;
  position: relative;
  margin-bottom: 2.875rem;
}

.html-input--container .editor-input {
  font-weight: 500;
  color: #343434 !important;
}

.html-input--inner {
  border-top: 2px solid #ccc;
}

.html-input--container .editor-heading-h1 {
  font-size: 2rem !important;
  color: #000 !important;
  font-weight: 700 !important;
}

.html-input--container .editor-heading-h2 {
  font-size: 1.75rem !important;
  color: #000 !important;
  font-weight: 700 !important;
}

.html-input--container .editor-heading-h3 {
  font-size: 1.5rem !important;
  color: #000 !important;
  font-weight: 700 !important;
}

.html-input--placeholder {
  position: absolute;
  top: 3.85rem;
  left: .66rem;
  opacity: 0.5;
}

.link-editor {
  display: flex;
  flex-direction: row;
  margin-left: 4rem;
  z-index: 9999;
  overflow: hidden;
}
.html-input--done-button {
  border: 0;
  outline: none;
  font-family: inherit;
  font-size: 1rem;
  padding: 0;
  width: 4rem;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.html-input--icon-checkmark {
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(./icons/custom-checkmark.svg);
}

.link-editor .link-input a {
  pointer-events: none;
  cursor: default;
  text-decoration: none;

}
.html-input--image-container {
  position: relative;
}

.html-input--image-container .html-input--image-remove-button {
  display: none;
}
.html-input--image-container:hover .html-input--image-remove-button {
  display: flex;
}

.html-input--image-remove-button {
  border-radius: 10rem;
  cursor: pointer;
  position: absolute;
  top: .25rem;
  left: .25rem;
  height: 2rem;
  width: 2rem;
  background-color: black;
  align-items: center;
  justify-content: center;
}
.html-input--icon-remove {
  height: 1.25rem;
  width: 1.25rem;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(./icons/custom-delete.svg);
}
.html-input--icon-image {
  opacity: 0.6;
  display: block;
  margin-top: .15rem;
  height: 1rem;
  width: 1rem;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(./icons/custom-image.svg);
}

.html-input--container .editor-input {
  background-color: #f7f7f7;
}

.html-input--container .editor-input:focus {
  background-color: #ffffff;
}
